import React from 'react';

import PageWrapper from '../components/PageWrapper';

const Faq = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-right',
          headerButton: (
            <>
              <a href='https://app.intry.io/signup'>
                <button className='btn btn btn-blue-3 header-btn rounded-5'>Sign up</button>
              </a>
            </>
          ),
          footerStyle: 'style3',
        }}
      >
        <div className='pt-23 pt-md-26 pt-lg-31 pb-13 pb-md-18 pb-lg-23' style={{background: 'linear-gradient(to bottom, var(--bg-3), #ffffff)'}}>
          <div className='container'>
            {/* Section Title */}
            <div className='row justify-content-center'>
              <div className='col-xl-8 col-lg-9'>
                <div className='text-center mb-13 mb-lg-18'>
                  <h2 className='font-size-11 mb-7'>Frequently Asked Question</h2>
                  <p className='font-size-7 mb-0 px-md-10 px-xl-13'>
                    We focus on visibility and transparency, so all questions you may have shall be answered
                  </p>
                </div>
              </div>
            </div>
            {/* End Section Title */}
            {/* Faqs */}
            {/* Faqs */}
            <div className='row justify-content-center'>
              {/* Single Faq */}
              <div className='col-lg-6 col-md-10'>
                <div className='d-flex mb-10 mb-lg-17'>
                  <div className='mr-6'>
                    <div className='bg-light-orange circle-28 text-white mt-1'>
                      <i className='fa fa-question' />
                    </div>
                  </div>
                  <div className=''>
                    <h4 className='font-size-7 mb-7'>How to contact support?</h4>
                    <p className='font-size-5 pr-md-10 pr-lg-0 mb-0'>
                      You can contact our support team by email using{' '}
                      <a href='mailto:support@intry.io'>support@intry.io</a>. We'll do out best to answer within 1
                      bushiness day, but feel free to send another message if you believe your first one didn't go
                      through.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              <div className='col-lg-6 col-md-10'>
                <div className='d-flex mb-10 mb-lg-17'>
                  <div className='mr-6'>
                    <div className='bg-light-orange circle-28 text-white mt-1'>
                      <i className='fa fa-question' />
                    </div>
                  </div>
                  <div className=''>
                    <h4 className='font-size-7 mb-7'>Are there any other pricing plans?</h4>
                    <p className='font-size-5 pr-md-10 pr-lg-0 mb-0'>
                      If you would like to get a custom pricing plan, please contact us at{' '}
                      <a href='mailto:support@intry.io'>support@intry.io</a>. Depending on the amount of seats you'll
                      need, we can possibly create a plan specifically for your needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-10'>
                <div className='d-flex mb-10 mb-lg-17'>
                  <div className='mr-6'>
                    <div className='bg-light-orange circle-28 text-white mt-1'>
                      <i className='fa fa-question' />
                    </div>
                  </div>
                  <div className=''>
                    <h4 className='font-size-7 mb-7'>Do you have trial periods?</h4>
                    <p className='font-size-5 pr-md-10 pr-lg-0 mb-0'>
                      We don't. There is a FREE tier called Basic on our <a href='/pricing'>Pricing & Plan</a> that can
                      be used to test all features of our services. The only thing that you pay for on the other plans
                      is the amount of available seats and storage.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-10'>
                <div className='d-flex mb-10 mb-lg-17'>
                  <div className='mr-6'>
                    <div className='bg-light-orange circle-28 text-white mt-1'>
                      <i className='fa fa-question' />
                    </div>
                  </div>
                  <div className=''>
                    <h4 className='font-size-7 mb-7'>Do you do refunds?</h4>
                    <p className='font-size-5 pr-md-10 pr-lg-0 mb-0'>
                      Generally we don't do refunds, but if you believe you have an special case that demands a refund,
                      please contact us at <a href='mailto:support@intry.io'>support@intry.io</a> with as many details
                      as you can of your case. Depending on our decision we may process a refund for your account.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-10'>
                <div className='d-flex mb-10 mb-lg-17'>
                  <div className='mr-6'>
                    <div className='bg-light-orange circle-28 text-white mt-1'>
                      <i className='fa fa-question' />
                    </div>
                  </div>
                  <div className=''>
                    <h4 className='font-size-7 mb-7'>Do we get any updates after purchase?</h4>
                    <p className='font-size-5 pr-md-10 pr-lg-0 mb-0'>
                      Yes, intry.io is a live service that will have multiple updates moving forward, all those updates
                      will be available to both free and paid accounts.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-10'>
                <div className='d-flex mb-10 mb-lg-17'>
                  <div className='mr-6'>
                    <div className='bg-light-orange circle-28 text-white mt-1'>
                      <i className='fa fa-question' />
                    </div>
                  </div>
                  <div className=''>
                    <h4 className='font-size-7 mb-7'>Can i cancel my subscription?</h4>
                    <p className='font-size-5 pr-md-10 pr-lg-0 mb-0'>
                      Yes, at intry.io you can cancel your subscription at any time, once your subscription period ends,
                      you'll be moved back to the Basic plan and all users that exceed the limit of 5 seats will be
                      disabled.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Button  */}
            <div className='text-center pt-lg-3'>
              <p className='font-size-6 mb-0'>
                Still have a question about our service?
                <a className='text-blue-3 btn-link-with-underline ml-5' href='mailto:support@intry.io'>
                  Contact with us
                </a>
              </p>
            </div>
            {/* Button End */}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Faq;
